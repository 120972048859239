<template>
  <div class="orderView">
    <div class="nav-header">
      <div class="back-btn" @click="$emit('close')">
        <van-icon name="arrow-left" />
      </div>
      <!-- {{ this_token }} -->
      <div class="token-info">
        <div class="token-info-left">
          <img :src="this_token.tokenImg" class="token-icon" alt="" />
          <span class="token-name">{{ this_token.name }}</span>
        </div>
        <div class="token-info-right">
          <div class="token-price" v-if="this_token.name !== 'USDT'">
            ${{ formatKtoUSDT(this_token.price) }}
            <span
              class="token-price-change"
              :class="
                Number(this_token?.price) > Number(this_token?.price24h)
                  ? 'up'
                  : 'down'
              "
            >
              {{ this_token?.increase }}
            </span>
          </div>
          <div class="token-price" v-else>
            {{ $utils.numToK(this_token.price, 4, 2) }}
            K
            <span
              class="token-price-change"
              :class="
                Number(this_token?.price) > Number(this_token?.price24h)
                  ? 'up'
                  : 'down'
              "
            >
              {{ this_token?.increase }}
            </span>
          </div>
        </div>
      </div>
      <div class="placeholder"></div>
    </div>
    <div class="trade-switch">
      <div
        class="switch-btn"
        :class="{ active: buySellType === 1 }"
        @click="handleCheckBugSell(1)"
      >
        {{ $t("order.t28") }}
      </div>
      <div
        class="switch-btn"
        :class="{ active: buySellType === 2 }"
        @click="handleCheckBugSell(2)"
      >
        {{ $t("order.t29") }}
      </div>
    </div>
    <div class="order-content">
      <div class="market-orders">
        <table class="orders-table">
          <thead>
            <tr>
              <th>
                {{ "#" }}
              </th>
              <th>{{ $t("delegation.t10") }} (K)</th>
              <th>{{ $t("delegation.t31") }} (U)</th>
              <th>{{ $t("delegation.t24") }}</th>
              <!-- <th>{{ $t("delegation.t23") }}</th> -->
            </tr>
          </thead>

          <tbody class="sell-orders">
            <tr
              v-for="(item, index) in notFinishList"
              :key="'sell' + index"
              :class="buySellType === 2 ? 'order-row sell' : 'order-row buy'"
              @click="handleSelectPrice(item)"
            >
              <td class="index">
                {{ index + 1 }}
              </td>
              <td class="price compact">
                <div class="k-price">
                  {{ $utils.numToK(item.price, 4, 2) }}
                </div>
                <!-- <div class="usdt-price">
                  ≈ {{ formatKtoUSDT(item.price) }} USD
                </div> -->
              </td>
              <td class="amount compact">≈ {{ formatKtoUSDT(item.price) }}</td>
              <td class="total compact">
                {{ $utils.numToK(item.remainAmount, 5, 2) }}
              </td>
            </tr>
            <tr v-if="notFinishList.length === 0">
              <td colspan="4" style="text-align: center">
                {{ $t("home.t14") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template v-if="buySellType === 1">
      <dl class="sale">
        <div class="price-wrapper">
          <dt>{{ $t("delegation.t10") }}</dt>
          <div class="price-usdt">
            {{ formatKtoUSDT(buy.price) }}
            USD
          </div>
        </div>
        <dd>
          <div class="input-wrapper">
            <!-- <input
              v-model="buy.price"
              type="number"
              :disabled="buyLoading"
              placeholder="0.0001"
              class="amount-input"
            /> -->
            <van-field
              v-model="buy.price"
              :disabled="buyLoading"
              type="number"
              maxlength="14"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <div class="unit">K</div>
          </div>
        </dd>

        <dt class="amount-header">
          <span>{{ $t("delegation.t11") }}</span>
          <span class="balance-info">
            {{ $t("delegation.t15") }}: {{ $utils.numToK(buy.balance, 4, 2) }} K
          </span>
        </dt>
        <dd>
          <div class="input-wrapper">
            <van-field
              v-model="buy.amount"
              type="number"
              :disabled="buyLoading"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <button class="max-btn" @click="handleMaxBuy">MAX</button>
            <div class="unit">{{ this_token.name }}</div>
          </div>
        </dd>
        <div class="fee-details">
          <div class="fee-item">
            <span class="label">{{
              $t("delegation.t13", { n: buy.fee })
            }}</span>
            <span class="value">{{ buyFeeTotal }} K</span>
          </div>
          <div class="fee-item">
            <span class="label">{{ $t("delegation.t14") }}</span>
            <span class="value">{{ buyTotal }} K</span>
          </div>
        </div>
      </dl>
    </template>
    <template v-if="buySellType === 2">
      <dl class="sale">
        <div class="price-wrapper">
          <dt>{{ $t("delegation.t10") }}</dt>
          <div class="price-usdt">
            {{ formatKtoUSDT(sell.price) }}
            USD
          </div>
        </div>
        <dd>
          <div class="input-wrapper">
            <van-field
              v-model="sell.price"
              :disabled="sellLoading"
              type="number"
              maxlength="14"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <div class="unit">K</div>
          </div>
        </dd>

        <dt class="amount-header">
          <span>{{ $t("delegation.t16") }}</span>
          <span class="balance-info">
            {{ $t("delegation.t15") }}: {{ $utils.numToK(sell.balance, 4, 2) }}
            {{ this_token.name }}
          </span>
        </dt>
        <dd>
          <div class="input-wrapper">
            <van-field
              v-model="sell.amount"
              :disabled="sellLoading"
              type="number"
              maxlength="14"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <button class="max-btn" @click="handleMaxSell">MAX</button>
            <div class="unit">{{ this_token.name }}</div>
          </div>
        </dd>
        <div class="fee-details">
          <div class="fee-item">
            <span class="label">{{
              $t("delegation.t13", { n: sell.fee })
            }}</span>
            <span class="value">{{ sellFeeTotal }} {{ this_token.name }}</span>
          </div>
          <div class="fee-item">
            <span class="label">{{ $t("delegation.t14") }}</span>
            <span class="value">{{ sellTotal }} {{ this_token.name }}</span>
          </div>
        </div>
      </dl>
    </template>

    <div class="bottom-button">
      <van-button
        :loading="buyLoading || sellLoading"
        :class="buySellType === 1 ? 'buy-btn' : 'sell-btn'"
        @click="buySellType === 1 ? handleBuy() : handleSell()"
      >
        {{ buySellType === 1 ? $t("delegation.t8") : $t("delegation.t9") }}
        {{ this_token.name }}
      </van-button>
    </div>

    <div class="history-records">
      <div class="tabs">
        <div
          class="tab-item"
          :class="{ active: activeTab === 'orders' }"
          @click="changeTab('orders')"
        >
          {{ $t("delegation.t1") }}
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'trades' }"
          @click="changeTab('trades')"
        >
          {{ $t("delegation.t3") }}
        </div>
      </div>

      <div class="record-list">
        <div
          v-for="(item, index) in orderList"
          :key="index"
          class="record-item"
        >
          <div class="record-main">
            <div class="record-left">
              <div :class="['type-tag', item.type]">
                {{
                  item.type === "buy"
                    ? $t("delegation.t8")
                    : $t("delegation.t9")
                }}
              </div>
              <div class="amount-info">
                <span class="amount">
                  ≈ {{ formatKtoUSDT(item.price) }} USD
                </span>
              </div>
            </div>

            <div class="record-right">
              <div class="status" :class="item.status">
                {{ getStatusLabel(item.status) }}
              </div>
            </div>
          </div>

          <div class="record-details">
            <div class="detail-row">
              <span class="label">{{ $t("delegation.t10") }}</span>
              <span class="value">{{ $utils.numToK(item.price, 4, 2) }} K</span>
            </div>
            <div class="detail-row" v-if="item.status !== 'finish'">
              <span class="label">{{ $t("delegation.t23") }}</span>
              <span class="value"
                >{{ $utils.numToK(item.remain_amount, 5, 2) }} /
                {{ $utils.numToK(item.amount, 5, 2) }}
                {{ getTokenByAddress(item.token)?.name }}</span
              >
            </div>
            <div class="detail-row" v-else>
              <span class="label">{{
                item.type === "buy"
                  ? $t("delegation.t11")
                  : $t("delegation.t16")
              }}</span>
              <span class="value">
                {{ $utils.numToK(item.amount, 5, 2) }}
                {{ getTokenByAddress(item.token)?.name }}</span
              >
            </div>
            <div class="detail-row">
              <span class="label">{{ $t("order.t6") }}</span>
              <span class="value">{{ formatTimeStamp(item.create_time) }}</span>
            </div>
            <div class="detail-row">
              <span class="label">Hash</span>
              <span class="value hash" @click="toHash(item.hash)">{{
                handle_hash(item.hash)
              }}</span>
            </div>
          </div>

          <div
            v-if="activeTab === 'orders' && item.status !== 'finish'"
            class="record-actions"
          >
            <van-button
              @click="cancelOrder(item, index)"
              type="info"
              v-if="
                activeTab === 'orders' &&
                (item.status === 'buying' || item.status === 'selling')
              "
              :loading="item.loading"
              :loading-text="cancelLoadingTXT"
            >
              {{ $t("order.t22") }}
            </van-button>
            <van-button
              type="default"
              disabled
              v-if="
                activeTab === 'orders' &&
                (item.status === 'cancelBuy' || item.status === 'cancelSell')
              "
              :loading="item.loading"
              :loading-text="cancelLoadingTXT"
            >
              {{ $t("order.t23") }}
            </van-button>
          </div>
        </div>
        <!-- <div class="record-list-more">
          <van-button type="default" @click="() => onRefresh(false, true)">{{
            $t("delegation.t27")
          }}</van-button>
        </div> -->

        <div v-if="orderList.length === 0" class="empty-list">
          <img src="../assets/no_record.png" alt="暂无数据" />
          <p>{{ $t("home.t14") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant-green";
import { getContract, getWeb3, sleep } from "@/utils/utils";
import { ethers } from "ethers";
import Big from "big.js";

export default {
  name: "OrderView",
  props: {
    type: {
      type: String,
      default: "buy",
    },
  },
  data() {
    return {
      buySellType: 1,
      buyLoading: false,
      sellLoading: false,
      buy: {
        price: "",
        amount: "",
        fee: 0,
        balance: 0,
      },
      sell: {
        price: "",
        amount: "",
        fee: 0,
        balance: 0,
      },
      page: 0,
      x: 0,
      this_token: this.$store.state.this_token2,
      list: [],
      cancelIndex: "",
      cancelLoading: false, //确认购买loading
      cancelLoadingTXT: "", //确认购买loading文本
      language: this.$store.state.language,
      notFinishList: [],
      activeTab: "orders",
      orderList: [],
      orderTimer: null,
    };
  },
  computed: {
    buyFeeTotal() {
      let kprice = this.buy.price;
      return new Big(kprice || 0)
        .times(this.buy.amount || 0)
        .times(this.buy.fee / 100)
        .toFixed(6);
    },
    sellFeeTotal() {
      return new Big(this.sell.amount || 0)
        .times(this.sell.fee / 100)
        .toFixed(6);
    },

    buyTotal() {
      let kprice = this.buy.price;
      return new Big(kprice || 0)
        .times(this.buy.amount || 0)
        .times(this.buy.fee / 100 + 1)
        .toFixed(6);
    },
    sellTotal() {
      return new Big(this.sell.amount || 0)
        .times(this.sell.fee / 100 + 1)
        .toFixed(6);
    },
  },
  async mounted() {
    //查询列表
    this.getData();
    this.initFee();
    this.getNotFinishOrder();
    this.startOrderTimer();
  },
  async beforeDestroy() {
    this.orderTimer && clearInterval(this.orderTimer);
  },
  watch: {
    type: {
      immediate: true,
      handler(newType) {
        this.buySellType = newType === "buy" ? 1 : 2;
        this.initFee();
        this.getPrice();
      },
    },
    "$store.state.changeNum": function () {
      setTimeout(() => {
        this.computeX(this.type);
      }, 0);
    },
    activeTab: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
    "$store.state.token_info2": {
      handler(val) {
        if (val) {
          this.this_token = val;
        }
      },
    },
    "buy.price": {
      handler(val) {
        if (val) {
          // 限制为4位小数
          const reg = /^\d*\.?\d{0,4}$/;
          if (!reg.test(val)) {
            // 如果不符合格式,截取到4位小数
            this.buy.price = Number(val).toFixed(4);
          }
        }
      },
    },
    "buy.amount": {
      handler(val) {
        if (val) {
          // 限制为4位小数
          const reg = /^\d*\.?\d{0,4}$/;
          if (!reg.test(val)) {
            // 如果不符合格式,截取到4位小数
            this.buy.amount = Number(val).toFixed(4);
          }
        }
      },
    },
    "sell.price": {
      handler(val) {
        if (val) {
          // 限制为4位小数
          const reg = /^\d*\.?\d{0,4}$/;
          if (!reg.test(val)) {
            this.sell.price = Number(val).toFixed(4);
          }
        }
      },
    },
    "sell.amount": {
      handler(val) {
        if (val) {
          const reg = /^\d*\.?\d{0,4}$/;
          if (!reg.test(val)) {
            this.sell.amount = Number(val).toFixed(4);
          }
        }
      },
    },
  },
  methods: {
    async handleCheckBugSell(index) {
      this.buySellType = index;
      await this.getNotFinishOrder();
      await this.initFee();
      await this.getPrice();
    },
    async getPrice() {
      const result = await this.$api.request(
        "/get_min_price",
        {
          token: this.this_token.address,
        },
        "POST"
      );
      if (result.code === 200) {
        if (result.data.buy) {
          this.buy.price = result.data.buy;
        }
        if (result.data.sell) {
          this.sell.price = result.data.sell;
        }
      }
    },
    async initFee() {
      const market2Contract = await getContract("Market2Contract");
      const [sellFee, buyFee, feeDenominator] = await Promise.all([
        market2Contract.methods.sellFee().call(),
        market2Contract.methods.buyFee().call(),
        market2Contract.methods.feeDenominator().call(),
      ]);
      const wallet = localStorage.getItem("userAccount");
      const sell = ethers.utils.formatUnits(sellFee, 18);
      const buy = ethers.utils.formatUnits(buyFee, 18);
      const fee = ethers.utils.formatUnits(feeDenominator, 18);
      this.buy.fee = (buy / fee) * 100;
      this.sell.fee = (sell / fee) * 100;
      const web3 = await getWeb3();
      const buyBalance = await web3.eth.getBalance(wallet); // 余额
      this.buy.balance = ethers.utils.formatUnits(
        buyBalance,
        this.this_token.decimal
      ); // 余额18位转正常
      const erc20Contract = await getContract(
        "ERC20Contract",
        this.this_token.address
      ); // erc20约
      const sellBalance = await erc20Contract.methods.balanceOf(wallet).call(); // 余额
      this.sell.balance = ethers.utils.formatUnits(
        sellBalance,
        this.this_token.decimal
      ); // 余额18位转正常
    },
    async checkMarketOrderAmount(type, oid) {
      try {
        const market2Contract = await getContract("Market2Contract");
        const result = await market2Contract.methods[
          `${type}GoodsPriceIndexed`
        ](oid).call();
        var price = ethers.utils.formatUnits(result, this.this_token.decimal); // 余额18位转正常
        console.log(`检查合约的订单单价 => OrderId: ${oid} 单价: ${price}`);
        const index = await market2Contract.methods[`${type}GoodsIndexed`](
          oid
        ).call();
        const indexbig = new Big(index).minus(1).toFixed();
        var m = await market2Contract.methods[`${type}AllGoods`](
          result,
          indexbig
        ).call();
        var remain = ethers.utils.formatUnits(
          m.remain,
          this.this_token.decimal
        ); // 余额18位转正常
        console.log(
          `检查合约的订单 => Index: ${indexbig} OrderId: ${oid} 剩余数量: ${remain}`
        );
        return m;
      } catch (error) {
        console.log(error);
      }
    },
    async handleBuy() {
      const rel = /^\d+(\.\d{0,4})?$/;
      if (this.buy.price <= 0 || this.buy.amount <= 0) {
        Notify({ type: "danger", message: this.$t("delegation.t29") });
        return;
      }
      if (
        !rel.test(Number(this.buy.price).toString()) ||
        !rel.test(Number(this.buy.amount).toString())
      ) {
        Notify({ type: "danger", message: this.$t("delegation.t29") });
        return;
      }
      try {
        this.buyLoading = true;
        const token = this.this_token.address;
        const market2Contract = await getContract("Market2Contract");
        const wallet = localStorage.getItem("userAccount");
        const buyPrice = new Big(this.buy.price)
          .times(this.buy.amount)
          .times(this.buy.fee / 100 + 1)
          .toFixed();
        const web3 = await getWeb3();
        let balance = await web3.eth.getBalance(wallet); // 余额
        balance = ethers.utils.formatUnits(balance, this.this_token.decimal); // 余额18位转正常
        console.info("K币余额: ", balance);
        if (new Big(buyPrice).gt(balance)) {
          // 需要支付的代币高于余额
          Notify({ type: "danger", message: this.$t("home.t35") });
          return;
        }
        // 个数
        const amount = ethers.utils
          .parseUnits(this.buy.amount.toString(), this.this_token.decimal)
          .toString();
        // 单价
        const price = ethers.utils
          .parseUnits(this.buy.price.toString(), this.this_token.decimal)
          .toString();
        console.info("购买参数: ", token, amount, price);
        await market2Contract.methods.buyGoods(token, amount, price).send({
          from: wallet,
          value: ethers.utils
            .parseUnits(
              new Big(this.buy.price)
                .times(this.buy.amount)
                .times(this.buy.fee / 100 + 1)
                .toFixed(),
              this.this_token.decimal
            )
            .toString(),
        });
        await sleep(5 * 1000);
        this.$emit("refresh");
        this.buy.price = "";
        this.buy.amount = "";
        Notify({ type: "success", message: this.$t("home.t37") });
      } catch (e) {
        console.error(e);
        Notify({ type: "danger", message: this.$t("home.t38") });
      } finally {
        this.buyLoading = false;
      }
    },
    async handleSell() {
      const rel = /^\d+(\.\d{0,4})?$/;
      if (this.sell.price <= 0 || this.sell.amount <= 0) {
        Notify({ type: "danger", message: this.$t("delegation.t30") });
        return;
      }
      if (
        !rel.test(Number(this.sell.price).toString()) ||
        !rel.test(Number(this.sell.amount).toString())
      ) {
        Notify({ type: "danger", message: this.$t("delegation.t30") });
        return;
      }
      try {
        this.sellLoading = true;
        const token = this.this_token.address;
        const market2Contract = await getContract("Market2Contract");
        const isToken = await market2Contract.methods
          .isTokenValid(token)
          .call();
        if (!isToken) {
          Notify({ type: "danger", message: this.$t("delegation.t20") });
          return;
        }
        const wallet = localStorage.getItem("userAccount");
        const sellPrice = new Big(this.sell.amount)
          .times(this.sell.fee / 100 + 1)
          .toFixed();
        const erc20Contract = await getContract("ERC20Contract", token); // erc20合约
        let balance = await erc20Contract.methods.balanceOf(wallet).call(); // 余额
        balance = ethers.utils.formatUnits(balance, this.this_token.decimal); // 余额18位转正常
        console.info(`代币 - ${this.this_token.name} 余额: `, balance);
        if (new Big(sellPrice).gt(balance)) {
          // 需要支付的代币高于余额
          Notify({ type: "danger", message: this.$t("home.t35") });
          return;
        }
        // 已经授权的代币数量
        let allowance = await erc20Contract.methods
          .allowance(wallet, market2Contract._address)
          .call();
        // 18位转正常
        allowance = ethers.utils.formatUnits(
          allowance,
          this.this_token.decimal
        );
        console.info(
          `代币 - ${this.this_token.name} 已经授权额度: `,
          allowance
        );
        if (new Big(sellPrice).times(1.2).gt(allowance)) {
          // 是否需要授权
          const approvePrice = ethers.utils
            .parseUnits(
              new Big(sellPrice).times(1.2).toFixed(),
              this.this_token.decimal
            )
            .toString();
          await erc20Contract.methods
            .approve(market2Contract._address, approvePrice)
            .send({ from: wallet });
          while (true) {
            allowance = await erc20Contract.methods
              .allowance(wallet, market2Contract._address)
              .call();
            console.log("授权", approvePrice, allowance);
            if (!new Big(approvePrice).gt(allowance)) break;
          }
        }
        // 个数
        const amount = ethers.utils
          .parseUnits(this.sell.amount.toString(), this.this_token.decimal)
          .toString();
        // 单价
        const price = ethers.utils
          .parseUnits(this.sell.price.toString(), this.this_token.decimal)
          .toString();
        console.log(
          `卖出数量: ${this.sell.amount} 卖出单价: ${this.sell.price}`
        );
        console.info(
          `代币 - ${this.this_token.name} 卖出参数: `,
          token,
          amount,
          price
        );
        await market2Contract.methods.sellGoods(token, amount, price).send({
          from: wallet,
        });
        await sleep(5 * 1000);
        this.$emit("refresh");
        this.sell.price = "";
        this.sell.amount = "";
        Notify({ type: "success", message: this.$t("home.t37") });
      } catch (e) {
        console.error(e);
        Notify({ type: "danger", message: this.$t("home.t38") });
      } finally {
        this.sellLoading = false;
      }
    },

    changeTab(type) {
      this.activeTab = type;
      this.orderList = [];
      this.page = 1;
    },
    computeX(type) {
      //获取这个div的位置
      // const rect = this.$refs[`tab${type}`].getBoundingClientRect();
      // this.x = rect.x + rect.width / 2 - 15 - 7;
    },
    handle_hash(hash) {
      return hash.substr(0, 10) + "...." + hash.substr(hash.length - 8);
    },
    async getData() {
      try {
        const type = this.activeTab === "orders" ? "2" : "3";
        const result = await this.$api.request(
          "/get_my_order2",
          {
            address: this.this_token.address,
            account: localStorage.getItem("userAccount"),
            type: type,
            page: this.page,
          },
          "POST"
        );

        if (result.code === 200) {
          // this.orderList = result.data;
          if (this.activeTab === "orders") {
            this.orderList = result.data.filter(
              (item) =>
                item.token === this.this_token.address &&
                item.status !== "finish" &&
                item.status !== "cancelBuy" &&
                item.status !== "cancelSell"
            );
          } else {
            this.orderList = result.data.filter(
              (item) =>
                item.status === "finish" ||
                item.status === "cancelBuy" ||
                item.status === "cancelSell"
            );
          }
        } else {
          this.orderList = [];
        }
      } catch (error) {
        console.error("getData error:", error);
        this.orderList = [];
        Notify({
          type: "danger",
          message: this.$t("home.t33"),
        });
      }
    },
    async onRefresh(reload = true, pageadd = false) {
      this.page = pageadd ? this.page + 1 : 1;
      if (reload) {
        this.list = [];
        this.this_token = this.$store.state.this_token2;
      }
      await this.initFee();
      await this.getPrice();
      await this.getNotFinishOrder();
    },
    async cancelOrder(item, index) {
      try {
        this.$set(this.list, index, { ...this.list[index], loading: true });
        const wallet = localStorage.getItem("userAccount");
        const market2Contract = await getContract("Market2Contract");
        if (item.type === "buy") {
          await market2Contract.methods
            .buyCancelGoods(item.order_id)
            .send({ from: wallet });
        } else if (item.type === "sell") {
          await market2Contract.methods
            .sellCancelGoods(item.order_id)
            .send({ from: wallet });
        }
        await sleep(5 * 1000);
        await this.getData();
        Notify({
          type: "success",
          message: this.$t("order.t24"), //Network error
        });
      } catch (error) {
        Notify({
          type: "danger",
          message: this.$t("order.t25"), //Network error
        });
      } finally {
        this.$set(this.list, index, { ...this.list[index], loading: false });
      }
    },
    toHash(hash) {
      window.open(this.$store.state.config.blockExplorerUrls[0] + "tx/" + hash);
    },
    async getNotFinishOrder() {
      const type = this.buySellType === 1 ? "sell" : "buy";
      const result = await this.$api.request(
        "/get_not_finish_order",
        {
          address: this.this_token.address,
          type: type,
          size: 2,
        },
        "POST"
      );
      let list = [];
      if (result.code === 200) {
        list = result.data[type].map((item) => {
          return {
            ...item,
          };
        });
        this.notFinishList = list;
      }
    },
    handleSelectPrice(item) {
      if (this.buySellType === 1) {
        this.buy.price = item.price;
        this.buy.amount = item.remainAmount;
      } else {
        this.sell.price = item.price;
        this.sell.amount = item.remainAmount;
      }
    },
    handleMaxBuy() {
      if (this.buy.price) {
        // 计算最大可买数量 = 余额 / (价格 * (1 + 手续费率))
        const maxAmount = new Big(this.buy.balance)
          .div(new Big(this.buy.price).times(this.buy.fee / 100 + 1))
          .round(4, 0) // 向下取整到4位小数
          .toString();
        this.buy.amount = maxAmount;
      }
    },
    handleMaxSell() {
      // 计算最大可卖数量 = 余额 / (1 + 手续费率)
      const maxAmount = new Big(this.sell.balance)
        .div(this.sell.fee / 100 + 1)
        .round(4, 0) // 向下取整到4位小数
        .toString();
      this.sell.amount = maxAmount;
    },
    getStatusLabel(status) {
      var map = {
        buying: this.$t("order.t27"),
        selling: this.$t("order.t27"),
        cancelBuy: this.$t("order.t22"),
        cancelSell: this.$t("order.t23"),
        finish: this.$t("order.t8"),
      };
      return map[status] ?? "-";
    },
    getTokenByAddress(address) {
      return this.$store.state.config.Tokens.find(
        (item) => item.address === address
      );
    },
    startOrderTimer() {
      this.orderTimer = setInterval(() => {
        this.getNotFinishOrder();
        this.getData();
      }, 3000);
    },
    formatTimeStamp(timeStamp) {
      if (typeof timeStamp !== "number") {
        timeStamp = parseInt(timeStamp);
      }
      timeStamp = timeStamp * 1000;
      const date = new Date(timeStamp);
      return date.toLocaleString("zh-CN", { hour12: false });
    },
    formatKtoUSDT(price) {
      const rate = this.$store.state.k_usdt_price || 0;
      return (Math.ceil((price / rate) * 100) / 100).toFixed(2);
    },
  },
  // created() {
  //   //查询列表
  //   this.getData();
  //   this.initFee();
  //   this.getNotFinishOrder();
  //   this.startOrderTimer();
  // },
};
</script>

<style lang="scss" scoped>
.orderView {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 84px;
}

.nav-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 1000;

  .back-btn {
    padding: 4px;
  }

  .token-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 8px;

    .token-info-left {
      display: flex;
      align-items: center;
      gap: 8px;

      .token-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .token-name {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .token-info-right {
      flex: 1;
      text-align: right;

      .token-price {
        font-size: 18px;
        font-weight: 500;
        border-radius: 4px;
        margin-left: 4px;

        .token-price-change {
          font-size: 12px;
          margin-left: 4px;
          &.up {
            color: #009944;
          }

          &.down {
            color: #f00;
          }
        }
      }
    }
  }
}

.trade-switch {
  display: flex;
  margin: 0 8px;
  border-radius: 4px;
  overflow: hidden;
  position: sticky;
  top: 50px;
  background: #fff;
  z-index: 100;

  .switch-btn {
    flex: 1;
    text-align: center;
    padding: 6px 0;
    font-size: 14px;
    cursor: pointer;
    background: #f5f5f5;
    border: 1px solid #eee;

    &.active {
      color: #fff;
      border: none;
      background: #2553ea;

      // &:first-child {
      //   background: #f00;
      // }

      // &:last-child {
      //   background: #009944;
      // }
    }
  }
}

.sale {
  padding: 0 16px;

  .price-wrapper {
    display: flex;
    justify-content: space-between;

    .price-usdt {
      padding: 0 4px;
      font-size: 13px;
      color: #666;
      display: flex;
      align-items: center;

      &::before {
        content: "≈";
        margin-right: 4px;
        color: #999;
      }
    }
  }

  dt {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 4px;
  }

  dd {
    margin: 0;
    margin-bottom: 4px;

    .input-wrapper {
      display: flex;
      align-items: center;
      background: #f8f9fa;
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 0 12px;
      height: 48px;
      transition: all 0.3s;

      &:focus-within {
        border-color: #2553ea;
        box-shadow: 0 0 0 2px rgba(37, 83, 234, 0.1);
      }

      .amount-input {
        flex: 1;
        border: none;
        outline: none;
        background: transparent;
        font-size: 16px;
        color: #333;
        padding: 0;
        min-width: 0; // 防input默认最小宽度

        &::placeholder {
          color: #999;
        }

        // 去除number类型的上下箭头
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .unit {
        font-size: 14px;
        color: #666;
        margin-left: 8px;
        white-space: nowrap;
      }
    }
  }

  .value-display {
    background: #f8f9fa;
    padding: 14px 12px;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
  }

  .balance-info {
    text-align: right;
    align-self: flex-end;
    margin-top: 8px;
    font-size: 12px;
    color: #666;
  }

  .amount-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .balance-info {
      font-size: 12px;
      color: #666;
    }
  }

  .input-wrapper {
    .max-btn {
      padding: 4px 8px;
      margin: 0 8px;
      border: 1px solid #2553ea;
      border-radius: 4px;
      color: #2553ea;
      background: transparent;
      font-size: 12px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: rgba(37, 83, 234, 0.1);
      }

      &:active {
        background: rgba(37, 83, 234, 0.2);
      }
    }
  }
}

.fee-details {
  margin-top: 8px;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 5px;

  .fee-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    .label {
      color: #666;
    }

    .value {
      color: #333;
      font-weight: 800;
      display: flex;
      align-items: center;
      gap: 4px;

      .van-icon {
        font-size: 14px;
        color: #666;
      }
    }
  }
}

.bottom-button {
  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
    z-index: 100;
  }

  button {
    width: calc(100% - 32px);
    height: 48px !important;
    border: none;
    border-radius: 5px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin: 4px 16px;

    &.buy-btn {
      background: #009944;
    }

    &.sell-btn {
      background: #f00;
    }
  }
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-top: 2px;

  th {
    background: #f8f9fa;
  }

  th,
  td {
    padding: 6px 0px;
    font-size: 14px;
    text-align: center; // 内容居中显示
  }

  .order-row {
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }

    .price {
      align-items: flex-start;
      gap: 4px;

      .k-price {
        font-size: 14px;
        font-weight: 500;
      }

      .usdt-price {
        font-size: 10px;
        color: #666;
      }
    }

    &.sell {
      .price {
        color: #f00;
      }
    }

    &.buy {
      .price {
        color: #009944;
      }
    }
  }
}

.market-orders {
  padding: 0px 12px;
}

.history-records {
  margin-top: 16px;
  padding: 0 16px;

  .tabs {
    display: flex;
    margin-bottom: 16px;
    border-bottom: 1px solid #eee;

    .tab-item {
      padding: 12px 24px;
      font-size: 14px;
      color: #666;
      cursor: pointer;
      position: relative;

      &.active {
        color: #333;
        font-weight: 500;

        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          background: #2553ea;
        }
      }
    }
  }

  .record-list {
    .record-item {
      background: #fff;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 12px;
      border: 1px solid #f0f0f0;

      .record-main {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 16px;

        .record-left {
          .type-tag {
            display: inline-block;
            padding: 4px 12px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 8px;

            &.buy {
              color: #009944;
              background: rgba(0, 153, 68, 0.1);
            }

            &.sell {
              color: #f00;
              background: rgba(255, 0, 0, 0.1);
            }
          }

          .amount-info {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .amount {
              font-size: 16px;
              font-weight: 500;
              color: #333;
            }

            .price {
              font-size: 14px;
              color: #666;
            }
          }
        }

        .record-right {
          text-align: right;

          .status {
            font-size: 13px;
            margin-bottom: 4px;

            &.finish {
              color: #009944;
            }
            &.pending {
              color: #faad14;
            }
          }

          .time {
            font-size: 12px;
            color: #999;
          }
        }
      }

      .record-details {
        padding: 12px;
        background: #f8f9fa;
        border-radius: 8px;
        margin-bottom: 12px;

        .detail-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 0;

          .label {
            font-size: 13px;
            color: #666;
          }

          .value {
            font-size: 13px;
            color: #333;

            &.hash {
              color: #2553ea;
              cursor: pointer;
            }
          }
        }

        .trade-hashes {
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px dashed #eee;

          .trade-hash-title {
            font-size: 13px;
            color: #666;
            margin-bottom: 8px;
          }

          .trade-hash-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0;

            .hash {
              color: #2553ea;
              cursor: pointer;
              font-size: 13px;
            }

            .time {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }

      .record-actions {
        text-align: right;
      }
    }

    .empty-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;

      img {
        width: 80px;
        height: 80px;
        opacity: 0.5;
        margin-bottom: 12px;
      }

      p {
        color: #999;
        font-size: 14px;
      }
    }

    .record-list-more {
      text-align: center;
      margin-top: 12px;
    }
  }
}

.record-item {
  .record-content {
    .info-row {
      .value {
        &.status {
          &.finish {
            color: #009944;
          }
          &.pending {
            color: #faad14;
          }
        }
      }
    }

    .trade-hashes {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px dashed #eee;

      .trade-hash-title {
        font-size: 13px;
        color: #666;
        margin-bottom: 8px;
      }

      .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;

        .time {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>
