<template>
  <div class="stock-layout">
    <!-- 头部信息 -->
    <div class="header">
      <div class="main-info">
        <div class="token-info" @click="showTokensPop">
          <img :src="token_info?.tokenImg" class="token-icon" alt="" />
          <span class="token-name">{{ token_info?.name }}</span>
          <img src="../assets/more_token.png" class="more" alt="" />
        </div>

        <div class="price-info">
          <div class="price-wrapper">
            <div class="current-price-wrapper">
              <div class="current-price" v-if="token_info?.name !== 'USDT'">
                $
                {{ formatKtoUSDT(token_info?.price) }}
              </div>
              <div class="current-price" v-else>
                {{ $utils.numToK(token_info?.price, 4, 2) }}
                <small>K</small>
              </div>
            </div>
            <div
              class="change-percent"
              :class="
                Number(token_info?.price) > Number(token_info?.price24h)
                  ? 'up'
                  : 'down'
              "
            >
              {{ token_info?.increase }}
            </div>
          </div>
        </div>
      </div>

      <div class="trading-info">
        <div class="info-item">
          <div class="label">{{ $t("home.t3") }}</div>
          <div class="value">
            {{ $utils.numToK(token_info?.day_deal, 4, 2) }}
          </div>
        </div>
        <div class="info-item">
          <div class="label">{{ $t("home.t4") }}</div>
          <div class="value" v-if="token_info?.name !== 'USDT'">
            {{ formatKtoUSDT(token_info?.day_max_price) }}
          </div>
          <div class="value" v-else>
            {{ $utils.numToK(token_info?.day_max_price, 4, 2) }}
          </div>
        </div>
        <div class="info-item">
          <div class="label">{{ $t("home.t5") }}</div>
          <div class="value" v-if="token_info?.name !== 'USDT'">
            {{ formatKtoUSDT(token_info?.day_min_price) }}
          </div>
          <div class="value" v-else>
            {{ $utils.numToK(token_info?.day_min_price, 4, 2) }}
          </div>
        </div>
      </div>
    </div>

    <div class="order-content">
      <div class="order-header">
        <div class="title" @click="showTokensPop">
          {{ $t("home.t9") }}
          <img src="../assets/more_token.png" class="more" alt="" />
        </div>
        <div class="tabs">
          <span
            v-for="(tab, index) in [$t('order.t28'), $t('order.t29')]"
            :key="index"
            :class="{ active: buySellType === index + 1 }"
            @click="handleCheckBuySell(index + 1)"
          >
            {{ tab }}
          </span>
        </div>
      </div>
      <div class="market-orders">
        <table class="orders-table">
          <thead>
            <tr>
              <th>
                {{ "#" }}
              </th>
              <th>{{ $t("delegation.t10") }} (K)</th>
              <th>{{ $t("delegation.t31") }} (U)</th>
              <th>{{ $t("delegation.t24") }}</th>
              <!-- <th>{{ $t("delegation.t23") }}</th> -->
            </tr>
          </thead>

          <tbody class="sell-orders">
            <tr
              v-for="(item, index) in notFinishList"
              :key="'sell' + index"
              :class="buySellType === 2 ? 'order-row sell' : 'order-row buy'"
            >
              <td class="index">
                {{ index + 1 }}
              </td>
              <td class="price compact">
                <div class="k-price">
                  {{ $utils.numToK(item.price, 4, 2) }}
                </div>
                <!-- <div class="usdt-price">
                  ≈ {{ formatKtoUSDT(item.price) }} USD
                </div> -->
              </td>
              <td class="amount compact">≈ {{ formatKtoUSDT(item.price) }}</td>
              <td class="total compact">
                {{ $utils.numToK(item.remainAmount, 5, 2) }}
              </td>
            </tr>
            <tr v-if="notFinishList.length === 0">
              <td colspan="4" style="text-align: center">
                {{ $t("home.t14") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- K线图表 -->
    <!-- <div class="chart-container" v-if="token_info.name != 'USDT'">
      <div id="container" ref="KLineDom"></div>
    </div> -->

    <!-- 交易动态信息 -->
    <div class="trade-dynamics">
      <div class="dynamics-header">
        <div class="title">{{ $t("delegation.t6") }}</div>
        <div class="tabs">
          <span
            v-for="(tab, index) in [
              $t('home.t55'),
              $t('delegation.t8'),
              $t('delegation.t9'),
            ]"
            :key="index"
            :class="{ active: dynamicsTab === index }"
            @click="dynamicsTab = index"
          >
            {{ tab }}
          </span>
        </div>
      </div>

      <div class="dynamics-list">
        <div v-if="finishList.length" class="list-content">
          <div
            v-for="(item, index) in filteredDynamics"
            :key="index"
            class="dynamics-item"
          >
            <div
              class="trade-type"
              :class="item.type === 'buy' ? 'buy' : 'sell'"
            >
              {{
                item.type === "buy" ? $t("delegation.t8") : $t("delegation.t9")
              }}
            </div>
            <div class="trade-info">
              <div class="price">
                <!-- <div class="k-price">
                  {{ formatPrice(item.price) }} {{ token_info?.pair_name }}
                </div> -->
                <div class="usdt-price" v-if="token_info?.name !== 'USDT'">
                  ≈ {{ formatKtoUSDT(item.price) }} USD
                </div>
                <div class="usdt-price" v-else>{{ item.price }} K</div>
              </div>
              <div class="amount">{{ item.amount }}</div>
            </div>
            <div class="trade-time">{{ item.updateTime }}</div>
          </div>
        </div>
        <div v-else class="empty-list">
          <img src="../assets/no_record.png" alt="暂无数据" />
          <p>{{ $t("home.t14") }}</p>
        </div>
      </div>
    </div>

    <!-- 底部操作按钮 -->
    <div class="bottom-actions">
      <button class="action-btn buy" @click="showBuyDialog">
        {{ $t("delegation.t8") }}
      </button>
      <button class="action-btn sell" @click="showSellDialog">
        {{ $t("delegation.t9") }}
      </button>
    </div>

    <!-- 订单弹窗 -->
    <van-popup
      v-model="showOrderDialog"
      round
      position="bottom"
      :style="{ height: '100%' }"
    >
      <Order2
        ref="order2"
        :key="orderKey"
        @refresh="onRefresh"
        :type="orderType"
        @close="showOrderDialog = false"
      />
    </van-popup>

    <!-- 添加代币列表弹窗 -->
    <van-popup
      v-model="show_tokens"
      round
      position="bottom"
      :style="{ maxHeight: '85%', minHeight: '75%' }"
    >
      <div class="pop_box">
        <!-- t9: 行情 -->
        <div class="title">{{ $t("home.t9") }}</div>
        <div class="pop_tabs">
          <span :class="{ ac: popIndex === 0 }" @click="popIndex = 0">{{
            $t("delegation.t21")
          }}</span>
          <span :class="{ ac: popIndex === 1 }" @click="popIndex = 1">{{
            $t("delegation.t22")
          }}</span>
        </div>
        <div class="subtotal">
          <div class="th">
            <div>
              <!-- t11: 币种 -->
              <span>{{ $t("home.t11") }}</span>
            </div>
            <div>
              <!-- t12: 最新价 -->
              <span>{{ $t("home.t12") }}</span>
            </div>
            <div>
              <!-- t13: 涨跌幅 -->
              <span>{{ $t("home.t13") }}</span>
            </div>
          </div>
          <div class="tbody" v-if="tokens.length > 0">
            <div
              class="tr"
              v-for="(item, index) in tokens.filter((item) =>
                popIndex === 0 ? true : starAddress.includes(item.address)
              )"
              :key="index"
              @click="selectToken(item)"
            >
              <div class="name">
                <img
                  v-if="!item.star"
                  class="star"
                  src="@/assets/star.png"
                  alt=""
                  @click.stop="handleStar(item)"
                />
                <img
                  v-else
                  class="star"
                  src="@/assets/star-ac.png"
                  alt=""
                  @click.stop="handleStar(item)"
                />
                <img :src="item.tokenImg" alt="" />
                <span>{{ item.name }}</span>
              </div>
              <div>
                <div v-if="item.name !== 'USDT'">
                  {{ formatKtoUSDT(item.price) }}
                  <small> U </small>
                </div>
                <div v-else>
                  {{ $utils.numToK(item.price, 4, 2) }}
                  <small> K </small>
                </div>
              </div>

              <div
                :class="
                  Number(item.price) !== Number(item.price24h)
                    ? Number(item.price) > Number(item.price24h)
                      ? 'green'
                      : 'red'
                    : ''
                "
              >
                {{ item.increase }}
              </div>
            </div>
          </div>
          <div v-else class="none">
            <img src="../assets/no_record.png" alt="" />
            <!-- t14: 暂无数据 -->
            <div>{{ $t("home.t14") }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from "vant-green";
import charts_config from "../utils/charts_config.json";
import VeLine from "v-charts/lib/line.common";
import Market from "../components/MarketView.vue";
import Trade from "../components/TradeView.vue";
import Order2 from "../components/OrderView2.vue";
import { createChart } from "lightweight-charts";
import { dateFormat, getContract } from "@/utils/utils";
import { Popup, Field } from "vant-green";

export default {
  components: {
    VeLine,
    Market,
    Trade,
    Order2,
    [Popup.name]: Popup,
    [Field.name]: Field,
  },
  data() {
    return {
      orderType: 0,
      popIndex: 0,
      chartIndex: 0,
      finishList: [],
      notFinishList: [],
      notFinishMoreList: [],
      starAddress: [],
      contract: null, //操作合约
      account: "",
      config: null,
      token_info: null,
      tokens: [],
      tokens_bak: [],
      chartData: charts_config.chartData,
      chartExtend: charts_config.chartExtend,
      timer: null,
      active: 0,
      show_tokens: false,
      show_order_list: false,
      search_txt: "",
      marketKey: 0, //市场
      tradeKey: 0, //交易
      orderKey: 0, //订单
      tabRef: ["market", "trade", "order2"],
      //手续费
      FeeRate: {
        buyFee: 0,
        sellFee: 0,
      },
      token_balance: 0, //用户token余额
      //挂单
      show_sell: false,
      amount: "",
      price: "",
      confirmSellLoading: false, //确认挂单loading
      confirmSellLoadingTXT: "",
      //买入
      show_buy: false,
      buy_amount: 0,
      pay_amount: 0,
      order_item: null, //选择的订单
      confirmBuyLoading: false, //确认购买loading
      confirmBuyLoadingTXT: "", //确认购买loading文本
      refreshLoading: false,
      show_token_info: false,
      addTokenLoading: false,
      chartKLine: null,
      candlestickSeries: null,
      showOrderDialog: false, // 控制订单弹窗
      orderType: "", // 'buy' 或 'sell'
      dynamicsTab: 0, // 交易动态tab索引
      tokenTimer: null,
      buySellType: 1, // 1: 买入 2: 卖出
    };
  },
  watch: {
    amount(val) {
      //最多4位小数
      if (val.indexOf(".") != -1) {
        val = val.slice(0, val.indexOf(".") + 5);
        this.amount = val;
      }
    },
    price(val) {
      //最多4位小数
      if (val.indexOf(".") != -1) {
        val = val.slice(0, val.indexOf(".") + 5);
        this.price = val;
      }
    },
  },
  async mounted() {
    this.account = localStorage.getItem("userAccount");
    this.config = this.$store.state.config;
    this.FeeRate = this.$store.state.FeeRate;
    this.token_info = this.$store.state.token_info2;
    this.tokens = this.config.Tokens;
    this.tokens_bak = this.tokens;
    this.chartExtend.tooltip = {
      trigger: "axis",
      formatter: (params) => {
        return (
          params[0].axisValueLabel +
          "<br/>" +
          params[0].marker +
          " Price: " +
          Number(Number(params[0].value[1]).toFixed(4)) +
          " " +
          this.token_info.pair_name
        );
      },
    };
    // this.chartData.rows = this.$store.state.token_info2.price_data;
    // this.contract = new this.$BlockchainContract(this.config);
    await this.updateUsdtPrice();
    this.onRefresh();
    this.startGetTokenInfo();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.tokenTimer && clearInterval(this.tokenTimer);
  },
  methods: {
    handleCheckBuySell(type) {
      this.buySellType = type;
      this.getNotFinishOrder();
    },
    handleKLine(data) {
      if (this.chartKLine) {
        const formattedData = data.map((item) => ({
          time: Math.floor(new Date(item.time).getTime() / 1000),
          open: parseFloat(item.open) / this.$store.state.k_usdt_price,
          high: parseFloat(item.high) / this.$store.state.k_usdt_price,
          low: parseFloat(item.low) / this.$store.state.k_usdt_price,
          close: parseFloat(item.close) / this.$store.state.k_usdt_price,
        }));

        this.candlestickSeries.setData(formattedData);
        this.chartKLine.timeScale().fitContent();
        return;
      }

      const chartOptions = {
        layout: {
          textColor: "#333",
          background: { type: "solid", color: "#fff" },
          fontSize: 12,
        },
        grid: {
          vertLines: { color: "#f0f0f0" },
          horzLines: { color: "#f0f0f0" },
        },
        timeScale: {
          borderColor: "#eee",
          timeVisible: true,
          secondsVisible: false,
          tickMarkFormatter: (time) => {
            const date = new Date(time * 1000);
            if (isNaN(date.getTime())) {
              return "";
            }
            return `${date.getDate()}/${date.getMonth() + 1}`;
          },
        },
        crosshair: {
          mode: 1,
          style: 0,
        },
        localization: {
          locale: "zh-CN",
          dateFormat: "dd/MM",
        },
      };

      this.chartKLine = createChart(this.$refs.KLineDom, chartOptions);

      this.candlestickSeries = this.chartKLine.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
        priceFormat: {
          type: "price",
          precision: 4,
          minMove: 0.001,
        },
      });

      const formattedData = data.map((item) => ({
        time: Math.floor(new Date(item.time).getTime() / 1000),
        open: parseFloat(item.open) / this.$store.state.k_usdt_price,
        high: parseFloat(item.high) / this.$store.state.k_usdt_price,
        low: parseFloat(item.low) / this.$store.state.k_usdt_price,
        close: parseFloat(item.close) / this.$store.state.k_usdt_price,
      }));

      this.candlestickSeries.setData(formattedData);
      this.chartKLine.timeScale().fitContent();
    },
    async handleShowMore() {
      try {
        const result = await this.$api.request(
          "/get_not_finish_order",
          {
            address: this.token_info.address,
            type:
              this.orderType === 0 ? "" : this.orderType === 1 ? "sell" : "buy",
            size: 50,
          },
          "POST"
        );
        if (result.code === 200) {
          this.notFinishMoreList = result.data;
        }
        this.show_order_list = true;
      } catch {}
    },
    async getFinishOrder() {
      const result = await this.$api.request(
        "/get_finish_order",
        { address: this.token_info.address },
        "POST"
      );
      if (result.code === 200) {
        this.finishList = result.data.map((item) => {
          return {
            ...item,
            usdt_price: item.price / this.$store.state.k_usdt_price,
            updateTime: dateFormat("MM/DD hh:mm:ss", item.update_time * 1000),
          };
        });
        // only keep 50 items
        this.finishList = this.finishList.slice(0, 50);
      }
    },
    async getNotFinishOrder() {
      const type = this.buySellType === 1 ? "sell" : "buy";
      const result = await this.$api.request(
        "/get_not_finish_order",
        {
          address: this.token_info.address,
          type: type,
          size: 10,
        },
        "POST"
      );
      let list = [];
      if (result.code === 200) {
        list = result.data[type].map((item) => {
          return {
            ...item,
          };
        });
        this.notFinishList = list;
      }
    },
    async handleOrderType(type) {
      this.orderType = type;
      await this.getNotFinishOrder();
    },
    handleStar(item) {
      let stars = JSON.parse(window.localStorage.getItem("star")) || [];
      if (item.star) {
        stars = stars.filter((child) => item.address !== child);
      } else {
        stars.push(item.address);
      }
      window.localStorage.setItem("star", JSON.stringify(stars));
      this.starAddress = stars;
      item.star = !item.star;
    },
    async onRefresh() {
      await this.selectToken(this.$store.state.this_token2, false);
      // await this.getFinishOrder();
      this.refreshLoading = false;
    },
    async updateUsdtPrice() {
      try {
        const result = await this.$api.request("/get_tokens_list2", {}, "POST");
        if (result.code === 200) {
          const usdt = result.data.filter((item) => item.name === "USDT")[0];
          if (!this.$store.state.this_token2) {
            this.$store.state.this_token2 = usdt;
          }
          this.$store.state.k_usdt_price = usdt.price;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$toast.clear();
      }
    },
    async showTokensPop() {
      //查询列表
      this.$toast.loading({
        message: this.$t("home.t32"), //加载中...
        forbidClick: true,
        duration: 0,
      });
      try {
        const market2Contract = await getContract("Market2Contract");
        const tokenList = await market2Contract.methods.getAllTokens().call();
        const result = await this.$api.request("/get_tokens_list2", {}, "POST");
        if (result.code === 200) {
          this.starAddress =
            JSON.parse(window.localStorage.getItem("star")) || [];
          this.tokens = result.data
            .filter((item) => tokenList.includes(item.address))
            .map((item) => {
              item.usdt_price = item.price / this.$store.state.k_usdt_price;
              item.star = this.starAddress.includes(item.address);
              return item;
            })
            .sort((a, b) =>
              a.name === "USDT" ? -1 : b.name === "USDT" ? 1 : 0
            );

          this.tokens_bak = result.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$toast.clear();
      }
      this.show_tokens = true;
    },
    async selectToken(item, isLoading = true) {
      item.usdt_price = item.price / this.$store.state.k_usdt_price;
      this.$store.state.this_token2 = item;
      localStorage.setItem("last_token2", JSON.stringify(item));
      this.$refs[this.tabRef[2]] &&
        this.$refs[this.tabRef[2]].onRefresh(isLoading);
      if (isLoading) {
        this.$toast.loading({
          duration: 0,
        });
      }
      this.show_tokens = false;
      await this.getTokenInfo();
      this.getFinishOrder();
      this.getNotFinishOrder();
    },
    all() {
      if (this.token_info.address == this.$store.state.address0) {
        this.amount = this.token_balance / (1 + this.FeeRate.sellFee) - 0.0003;
      } else {
        this.amount = this.token_balance / (1 + this.FeeRate.sellFee);
      }
      if (this.amount < 0) {
        this.amount = 0;
      }
    },
    showTokenInfoPop() {
      this.show_token_info = true;
    },
    addToken() {
      this.addTokenLoading = true;
      this.contract
        .addToken(this.token_info, this)
        .then((result) => {
          if (result === true) {
            Notify({
              type: "success",
              message: this.$t("home.t44"), //t44: 添加成功
            });
            this.show_token_info = false;
          } else {
            Notify({
              type: "danger",
              message: this.$t("home.t45"), //t45: 添加失败
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.addTokenLoading = false;
        });
    },
    formatPrice(price) {
      if (!price) return "0.00";
      return Number(price).toFixed(6);
    },
    formatAmount(amount) {
      if (!amount) return "0.00";
      return Number(amount).toFixed(4);
    },
    formatKtoUSDT(price) {
      const rate = this.$store.state.k_usdt_price || 0;
      return (Math.ceil((price / rate) * 100) / 100).toFixed(2);
    },
    showBuyDialog() {
      this.orderType = "buy";
      this.showOrderDialog = true;
    },
    showSellDialog() {
      this.orderType = "sell";
      this.showOrderDialog = true;
    },
    startGetTokenInfo() {
      this.timer = setInterval(() => {
        this.updateUsdtPrice();
        this.getTokenInfo();
        this.getFinishOrder();
        this.getNotFinishOrder();
      }, 10000);
    },
    async getTokenInfo() {
      try {
        const result = await this.$api.request(
          "/get_token_info2",
          this.$store.state.this_token2,
          "POST"
        );
        if (result.code === 200) {
          this.token_info = result.data;
          this.$store.state.token_info2 = result.data;
          this.chartData = {
            columns: ["ymd", "price"],
            rows: result.data.price_data,
          };
          // this.handleKLine(result.data.price_data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$toast.clear();
      }
    },
  },
  computed: {
    filteredDynamics() {
      if (this.dynamicsTab === 0) return this.finishList;
      return this.finishList.filter((item) =>
        this.dynamicsTab === 1 ? item.type === "buy" : item.type === "sell"
      );
    },
  },
};
</script>

<style scoped lang="scss">
#container {
  width: 100%;
  height: 280px;
  margin: 0;
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.pop_token {
  padding: 0 16px;
  box-sizing: border-box;

  .token {
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 8px;
    height: 56px;
    border-bottom: 1px solid #e5e5e5;

    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      object-fit: contain;
    }
  }

  .token_price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }
}

.order-class {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 22px;
    height: 22px;
  }
}

.pop_tabs {
  background-color: #f5f7fa;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 30px;
  box-sizing: border-box;

  span {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.ac {
      background-color: #2553ea;
      color: #fff;
    }
  }
}

p {
  margin: 0;
  padding: 0px;
}

.table {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;

  .table-title {
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f7fa;

    > span {
      flex: 1;
    }
  }

  .table-body {
    > li {
      width: 100%;
      min-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      &.sell:nth-of-type(2n),
      &.buy:nth-of-type(2n) {
        background-color: #f5f7fa;
      }

      &.more {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        justify-content: space-between;
        padding-right: 16px;
        padding-left: 16px;
      }

      > div {
        flex: 1;
      }
    }
  }
}

.chart-list {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;

  li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.ac {
      color: #2553ea;

      &::after {
        background-color: #2553ea;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -18px;
      width: 19px;
      height: 4px;
      transform: translateX(-50%);
    }
  }
}

.delegation {
  padding: 0 15px calc(env(safe-area-inset-bottom) + 10px) 15px;
  background-position-y: 50px;
  min-height: calc(100vh - 150px);
}

.box {
  background: #fff;
  border-radius: 10px;
  padding: 0 16px;
  margin-top: 8px;
}

.token_info {
  position: relative;

  .info_icon {
    position: absolute;
    width: 20px;
    right: 2px;
    top: 2px;
  }

  .token {
    border-bottom: 1px solid #e5e5e5;
  }

  .name {
    height: 60px;
    gap: 6px;
    font-size: 15px;

    .tokenimg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: contain;
    }

    .more {
      width: 10px;
    }

    .i_icon {
      width: 20px;
    }
  }

  .token_data {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > div {
      font-size: 12px;
      line-height: 1;

      div:nth-child(1) {
        color: #030c03;
      }

      div:nth-child(2) {
        color: #000;
        font-weight: bold;
      }
    }
  }

  .add_token {
    text-align: center;
    color: #1a89fa;
    // padding: 4px 0 10px;
    font-size: 10px;
  }
}

.chart {
  // padding: 20px 0;
  padding: 0 0 20px 0;
}

.content {
  margin-top: 10px;
  padding-bottom: 10px;
  min-height: calc(100vh - 69px - env(safe-area-inset-bottom));
}

.pop_box {
  min-height: 400px;
  padding: 0 20px;

  .title {
    text-align: center;
    line-height: 70px;
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    font-weight: 500;
  }

  .search_box {
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 44px;
    background-color: #f5f7fa;
    border-radius: 10px;
    border: solid 1px #d9d9d9;
    color: #000;

    .icon {
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 20px;
        height: 20px;
      }
    }

    .input {
      flex: 1;
      background: red;
      color: #000;
    }
  }

  .subtotal {
    margin-top: 16px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 50vh; // 设置一个固定高度

    .th {
      display: flex;
      gap: 0 4px;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      color: #030c03;
      min-height: 47px;
      padding: 2px 10px;
      align-items: center;
      text-align: center;
      background: #f5f7fa;
      position: sticky;
      top: 0;
      z-index: 1;

      > div {
        flex: 1;
        text-align: left;

        &:nth-child(1) {
          flex: 1.3;
        }

        &:nth-child(3) {
          flex: 0.6;
        }

        > span {
          color: #808080;
        }
      }
    }

    .tbody {
      flex: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      // 自定义滚动条样式
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background: #2553ea;
        border-radius: 3px;
      }

      .tr {
        display: flex;
        gap: 0 4px;
        justify-content: space-between;
        font-weight: normal;
        font-size: 14px;
        color: #030c03;
        min-height: 47px;
        padding: 2px 10px;
        align-items: center;
        text-align: center;

        > div {
          flex: 1;
          text-align: left;

          &:nth-child(1) {
            flex: 1.3;
          }

          &:nth-child(3) {
            flex: 0.6;
          }
        }

        &:nth-child(odd) {
          background: #f5f7fa;
        }

        .name {
          display: flex;
          align-items: center;
          gap: 6px;

          .star {
            width: 14px;
            height: 14px;
            object-fit: contain;
          }

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .item {
    margin-bottom: 24px;

    .input_label {
      margin-left: 2px;
      font-size: 14px;
      color: #000000;
      line-height: 1;
    }

    .input_box {
      margin-top: 10px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 44px;
      background-color: #f5f7fa;
      border-radius: 10px;
      border: solid 1px #d9d9d9;
      color: #000;

      &.disabled {
        background-color: #eee;
      }

      .icon {
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          width: 20px;
          height: 20px;
        }
      }

      .input {
        flex: 1;
        background: red;
        color: #000;
      }
    }

    .balance {
      // font-family: PingFang-SC-Medium;
      font-size: 12px;
      line-height: 1;
      color: #808080;
    }

    .all {
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      color: #2553ea;
    }
  }

  .total {
    padding: 10px;
    height: 70px;
    background: url("../assets/bj.png") no-repeat;
    background-image: linear-gradient(0deg, #f2f6ff 0%, #ccddff 100%);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000;

    .total_amount {
      flex: 1;
      font-size: 22px;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;

      .fee {
        font-size: 12px;
        color: #404040;
      }
    }
  }

  // position: relative;
  .btn_box {
    // position: absolute;
    left: 20px;
    right: 20px;
    bottom: calc(env(safe-area-inset-bottom) + 38px);
    display: flex;
    gap: 20px;
    margin: 40px 0;

    > button {
      flex: 1;
      height: 38px !important;
      border-radius: 20px !important;
    }
  }

  .bote {
    margin-top: 4px;
    font-size: 12px;
    color: #ff5722;
  }
}

.stock-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fff;

  .header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;

    .main-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .token-info {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        .more {
          width: 10px;
        }

        .token-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: contain;
        }

        .token-name {
          font-size: 16px;
          font-weight: 500;
        }

        .arrow-icon {
          width: 12px;
          height: 12px;
          margin-left: 2px;
          opacity: 0.6;
          filter: brightness(0);
        }
      }

      .price-info {
        display: flex;
        align-items: center;
        gap: 8px;

        .price-wrapper {
          display: flex;
          align-items: flex-end;
          gap: 8px;

          .current-price {
            font-size: 24px;
            font-weight: bold;
            line-height: 1;

            small {
              font-size: 12px;
              color: #666;
              margin-left: 4px;
            }
          }

          .change-percent {
            font-size: 12px;
            &.up {
              color: #009944;
            }
            &.down {
              color: #f00;
            }
          }
        }
      }
    }

    .trading-info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;

      .info-item {
        .label {
          font-size: 12px;
          color: #666;
        }
        .value {
          font-size: 13px;
          margin-top: 4px;
        }
      }
    }
  }

  .order-content {
    margin-top: 5px;
    .order-header {
      margin: 0 16px;
      padding: 5px 16px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 15px;
        font-weight: 500;

        .more {
          width: 10px;
        }
      }

      .tabs {
        display: flex;
        gap: 16px;

        span {
          font-size: 14px;
          color: #666;
          padding: 4px 8px;
          cursor: pointer;

          &.active {
            color: #2553ea;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              left: 50%;
              transform: translateX(-50%);
              width: 16px;
              height: 2px;
              background: #2553ea;
              border-radius: 1px;
            }
          }
        }
      }
    }

    .orders-table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #eee;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      thead {
        position: sticky;
        top: 0;
        background: #f8f9fa;
      }

      th,
      td {
        padding: 8px 12px;
        font-size: 13px;
        text-align: center; // 内容居中显示
      }

      .order-row {
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: rgba(0, 0, 0, 0.02);
        }

        .price {
          align-items: flex-start;
          gap: 4px;

          .k-price {
            font-size: 16px;
            font-weight: 500;
          }

          .usdt-price {
            font-size: 10px;
            color: #666;
          }
        }

        &.sell {
          .price {
            color: #f00;
          }
        }

        &.buy {
          .price {
            color: #009944;
          }
        }
      }
    }
  }

  .market-orders {
    padding: 12px;
    max-height: 40vh; // 设置最大高度为屏幕的50%
    overflow-y: auto; // 允许垂直滚动

    // 自定义滚动条样式
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background: #2553ea;
      border-radius: 4px;
    }
  }

  .chart-container {
    margin-top: 0;
    padding: 16px;
  }

  .order-book {
    height: 240px;
    padding: 0 16px;
    background: #fafafa;
    border-radius: 8px;
    margin: 0 16px;

    .title {
      font-size: 14px;
      color: #666;
      padding: 12px 0;
      border-bottom: 1px solid #eee;
    }

    .list {
      height: calc(100% - 40px);
      overflow-y: auto;

      .sell-item,
      .buy-item {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        font-size: 14px;

        span:first-child {
          color: #333;
        }

        span:last-child {
          color: #666;
        }
      }

      .sell-item {
        span:first-child {
          color: #f00;
        }
      }

      .buy-item {
        span:first-child {
          color: #009944;
        }
      }

      .current-price {
        text-align: center;
        padding: 8px 0;
        font-size: 16px;
        font-weight: 500;
        background: #f5f5f5;
        margin: 8px 0;
        border-radius: 4px;
      }
    }
  }

  .bottom-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 12px 16px calc(env(safe-area-inset-bottom) + 12px);
    background: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);

    .action-btn {
      flex: 1;
      height: 32px;
      border-radius: 22px;
      font-size: 16px;
      font-weight: 500;
      margin: 0 8px;

      &.buy {
        background: #009944;
        color: #fff;
        border: none;
      }

      &.sell {
        background: #f00;
        color: #fff;
        border: none;
      }
    }
  }
}

.trade-dynamics {
  margin: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .dynamics-header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 15px;
      font-weight: 500;
    }

    .tabs {
      display: flex;
      gap: 16px;

      span {
        font-size: 14px;
        color: #666;
        padding: 4px 8px;
        cursor: pointer;

        &.active {
          color: #2553ea;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            width: 16px;
            height: 2px;
            background: #2553ea;
            border-radius: 1px;
          }
        }
      }
    }
  }

  .dynamics-list {
    margin-bottom: 60px;

    .list-content {
      padding: 8px 16px;
    }

    .dynamics-item {
      display: flex;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid #f5f5f5;

      &:last-child {
        border-bottom: none;
      }

      .trade-type {
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 12px;

        &.buy {
          color: #009944;
          background: rgba(0, 153, 68, 0.1);
        }

        &.sell {
          color: #f00;
          background: rgba(255, 0, 0, 0.1);
        }
      }

      .trade-info {
        flex: 1;
        margin-left: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .price {
          font-size: 14px;
          font-weight: 500;

          .usdt-price {
            font-size: 12px;
            color: #666;
          }
        }

        .amount {
          font-size: 12px;
          color: #666;
          text-align: center;
          align-self: center;
        }
      }

      .trade-time {
        font-size: 12px;
        color: #999;
        margin-left: 12px;
        min-width: 80px;
        text-align: right;
      }
    }

    .empty-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;

      img {
        width: 80px;
        height: 80px;
        opacity: 0.5;
      }

      p {
        color: #999;
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }
}
</style>


