import { render, staticRenderFns } from "./TradeView.vue?vue&type=template&id=6547d548&scoped=true"
import script from "./TradeView.vue?vue&type=script&lang=js"
export * from "./TradeView.vue?vue&type=script&lang=js"
import style0 from "./TradeView.vue?vue&type=style&index=0&id=6547d548&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__jwnxr7kw2qy3fk4ahrxeovtfxy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6547d548",
  null
  
)

export default component.exports