import { ethers } from 'ethers';
import Web3 from 'web3';
import CryptoJS from 'crypto-js';
import Market2ABI from '../../abi/Market2ABI.json'
import ERC20ABI from '../../abi/ERC20ABI.json'
import Router2ABI from '../../abi/Router2ABI.json'
import store from '../store';

export function numToK(value, max = 9, min = 0) {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min, maximumFractionDigits: max,
    });
    return noExponents(formatter.format(value));
}

export function handleAmount(value, max = 9, min = 0) {
    let amount = Web3.utils.fromWei(value, 'ether');
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min, maximumFractionDigits: max,
    });
    return noExponents(formatter.format(amount));
}

export const noExponents = (exponent) => {
    const data = String(exponent).split(/[eE]/);
    if (data.length === 1) return data[0];
    let z = '', sign = exponent < 0 ? '-' : '', str = data[0].replace('.', ''), mag = Number(data[1]) + 1;
    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

export function int2hex(num, width) {
    /* 转换 */
    var hex = '0123456789abcdef'
    var s = ''
    while (num) {
        s = hex.charAt(num % 16) + s;
        num = Math.floor(num / 16)
    }
    if (typeof width === 'undefined' || width <= s.length) {
        return '0x' + s
    }
    var delta = width - s.length
    var padding = ''
    while (delta-- > 0) {
        padding += '0'
    }
    return '0x' + padding + s
}

export function idComplex(id, length) {
    return CryptoJS.MD5(id).toString().slice(0, length) + '00' + id;
}

let web3Js = undefined
export const getWeb3 = async () => {
    if (web3Js) return web3Js
    await ethereum.request({ method: 'eth_requestAccounts' })
    web3Js = new Web3(ethereum)
    const chain = await web3Js.eth.net.getId()
    const { chainId } = store.state.config
    if (Number(chain) !== Number(chainId)) {
        await ethereum.request({
            method: 'wallet_switchEthereumChain', params: [{ chainId: `0x${chainId.toString(16)}` }]
        })
    }
    return web3Js
}

let Market2Contract
let Router2Contract
let ERC20ContractMap = new Map()
/**
 * @param name {'Market2Contract'|'ERC20Contract'|'Router2Contract'}
 * @param address {String}
 * @returns {Promise<*|Contract<{}>>}
 */
export const getContract = async (name, address = '') => {
    const web3 = await getWeb3()
    switch (name) {
        case 'Market2Contract': {
            if (Market2Contract) return Market2Contract
            Market2Contract = new web3.eth.Contract(Market2ABI.abi, store.state.config.contract2)
            return Market2Contract
        }
        case 'ERC20Contract': {
            if (ERC20ContractMap.has(address)) {
                return ERC20ContractMap.get(address)
            }
            const contract = new web3.eth.Contract(ERC20ABI, address)
            ERC20ContractMap.set(address, contract)
            return contract
        }
        case 'Router2Contract': {
            if (Router2Contract) return Router2Contract
            Router2Contract = new web3.eth.Contract(Router2ABI.abi, '0x10ED43C718714eb63d5aA57B78B54704E256024E')
            return Router2Contract
        }
    }
}

export const sleep = (delay) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, delay)
    })
}

export const dateFormat = (type, val) => {
    let date = new Date()
    if (val) {
        date = new Date(val)
    } else {
        return ''
    }
    const YYYY = String(date.getFullYear())
    const YY = String(date.getFullYear()).substr(2)
    const m = date.getMonth() + 1
    const MM = m > 9 ? `${m}` : '0' + m
    const d = date.getDate()
    const DD = d > 9 ? `${d}` : '0' + d
    const h = date.getHours()
    const hh = h > 9 ? `${h}` : '0' + h
    const $m = date.getMinutes()
    const mm = $m > 9 ? `${$m}` : '0' + $m
    const s = date.getSeconds()
    const ss = s > 9 ? `${s}` : '0' + s
    const obj = {
        YYYY, YY, MM, DD, hh, mm, ss
    }
    if (type === 'year') return YYYY
    if (type === 'month') return `${YYYY}-${MM}`
    if (type === 'day') return `${YYYY}-${MM}-${DD}`
    return type.replace(/(YYYY)|(YY)|(MM)|(DD)|(hh)|(mm)|(ss)/g, (key) => obj[key])
}
