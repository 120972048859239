import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DelegationView from '../views/DelegationView.vue'

Vue.use(VueRouter)

const routes = [{ path: '/', redirect: '/home' }, {
    path: '*', redirect: '/home'
}, {path: '/home', name: 'home', component: HomeView}, {
    path: '/delegation', name: 'delegation', component: DelegationView
}]

const router = new VueRouter({
    routes
})

export default router
